import React, { useEffect } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { SEO } from 'src/components/SEO'

import { EditorialSerializer } from 'src/utils/serializer'

import { ArticleCard } from 'src/components/article/articleCard'
import { Image } from 'src/components/image'

import { useSetPage, useStore } from 'src/context/siteContext'

import {
  Facebook,
  Twitter,
  Mail
} from 'src/components/svgs'

export interface PageProps {
  pageContext: {
    modules: []
    slug: string
    text: any[]
    title: string
    image: {
      imageId: string
    }
    meta: {}
    defaultMeta: {}
  }
  path?: string
  preview?: boolean
}

const Article = ({
  path,
  pageContext,
  preview = false
}: PageProps) => {
  const { theme } = useStore()
  const setPage = useSetPage()
  const {
    title,
    modules,
    defaultMeta,
    text,
    slug,
    relatedArticles,
    author,
    image,
    meta
  } = pageContext

  useEffect(() => {
    setPage('article')
  }, [])


  return (
    <div className={cx('x site__theme', theme)}>
      {preview && (
        <div className='bcblue ac cw x p1'>This is a Preview</div>
      )}
      <SEO defaultMeta={defaultMeta} defaultTitle={title} metaInfo={meta} pagePath={`/article/${slug}`} />
      <div className='container--1300 mxa pl2 pr2'>
        <div className='row gutter--none df x fw mt8 pt4'>
          {image && (
            <div className='col c14 c7--800'>
              <Image className='x' imageId={image.imageId}  />
            </div>
          )}
          <div className={cx('col c14', {
            'c7--800': image
          })}>
            <div className='df jcc tc bg--blue article__hero color--white aic y x'>
              <div className='p3'>
                <h2 className='mt2 mb2'>
                  {title}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='mt2 mt5--1000 pt4--1000 pb4 pb8--800 container--1300 mxa pr'>
          <div className='article__share bb bc df jcb aic'>
            <h5 className='mono show--1000 mb2'>Share</h5>
            <button className='df jcs aic bg--transparent m0 p0 mb2 bn color--black'>
              <div className='share__icon color--black mr1'>
                <Twitter className=' ' />
              </div>
              <span className='color--black mono s14'>Twitter</span>
            </button>
            <button className='df jcs aic bg--transparent m0 p0 mb2 bn color--black'>
              <div className='share__icon color--black mr1'>
                <Facebook className=' ' />
              </div>
              <span className='color--black mono s14'>Facebook</span>
            </button>
            <button className='df jcs aic bg--transparent m0 p0 mb2 bn color--black'>
              <div className='share__icon color--black mr1'>
                <Mail className=' ' />
              </div>
              <span className='color--black mono s14'>E-Mail</span>
            </button>
          </div>
          {author && (
            <div className='article__author df jcb aic pt1 pb1 bb bc'>
              <span className='courier s14 show--1000 caps'>Written By:</span>
              <span className='courier s14 hide--1000 caps'>Written By: {author.name}</span>
              {author.image && (
                  <div className='article__author-image x pr'>
                    <Image className='pa x y object--fit' width={100} imageId={author.image.imageId} alt={author.name} />
                  </div>
                )}
              <span className='courier s14 show--1000 caps'>{author.name}</span>
            </div>
          )}
          <div className='mt4--800 mt0--1000'>
            <BlockContent blocks={text} serializers={EditorialSerializer} />
          </div>
        </div>
        <div className='row df fw mt2 pb4 bt bc gutter--none pl2 pr2 mt4--800 pl0--800 pr0--800'>
          {relatedArticles?.map((article, i) => (
            <ArticleCard {...article} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Article